import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EmailVerificationService} from '../../gen/api/emailVerification.service';
import {VerifyLoginEmailCredential} from '../../gen/model/verifyLoginEmailCredential';
import {Logger} from '../../util/logger';
import {AppConstants} from '../AppConstants';
import {LoginBaseComponent} from '../login-base.component';
import {ParamsService} from '../params.service';
import {AlertHandler} from '../no-concurrent-alerts-handler';
import {cardFooterFeedbackTransition} from '../animations';
import {animate, keyframes, query, style, transition, trigger} from '@angular/animations';
import LocationUtil, {getAppBaseHref} from '../../util/locationUtil';
import {Observable, timer} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {AppStateService, ParamsForRouteNav} from '../app-state.service';
import {RecoveryEmailAddress, RecoveryEmailService} from '../../gen';
import {ProcessingIndicatorService} from '../processing-indicator.service';
import ResponseUtil from '../../util/responseUtil';
import {HttpErrorResponse} from '@angular/common/http';


@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    cardFooterFeedbackTransition,
    trigger('sendValidationFeedbackTransition', [
      transition(
        'void => *',
        [
          query(':self', [
            animate('200ms ease-in',
              keyframes([
                style({ transform: 'scaleY(0)' }),
                style({ transform: 'scaleY(1)' }),
              ])
            )
          ])
        ]
      ),
      transition(
        '* => void',
        [
          query(':self', [
            animate('200ms ease-in',
              keyframes([
                style({ transform: 'scaleY(1)' }),
                style({ transform: 'scaleY(0)' }),
              ])
            )
          ])
        ]
      ),
    ]),
  ],
})
export class ValidateEmailComponent extends LoginBaseComponent implements OnInit, OnDestroy {
  private static INITIAL_SEND_TIMEOUT = 2;
  private static RESEND_TIMEOUT = 5;
  validationForm = new FormGroup({
    key: new FormControl('', [Validators.required]),
  });

  sendEmailForm = new FormGroup({});

  key: string;
  emailsent: boolean;
  emailfailed: boolean;
  genericError: boolean;
  validationFailed: boolean;
  validationCodeExpired: boolean;
  alreadyValidated: boolean;
  recoveryEmailId: string|undefined;
  recoveryEmail: RecoveryEmailAddress;
  tooManyAttemptsError: boolean;
  showServerError: string|undefined;
  private tooManyAttemptsTimeoutCounter: any;
  private tooManyAttemptsTimeout: number;
  sendEmailTimeout: number;
  private sendEmailTimeoutCounter: any;
  isRecoveryEmail: boolean;
  validationCheckFailed: boolean;


  constructor(
    route: ActivatedRoute,
    router: Router,
    private appStateService: AppStateService,
    @Inject('EmailVerificationApi') private emailVerificationApi: EmailVerificationService,
    @Inject('RecoveryEmailApi') private recoveryEmailApi: RecoveryEmailService,
    private paramsService: ParamsService,
    private alertHandler: AlertHandler,
    private logger: Logger,
    private translate: TranslateService,
    private processingService: ProcessingIndicatorService,
    hostElement: ElementRef,
    configuration: ConfigurationService) {
    super(route, router, hostElement, configuration);
  }
  ngOnInit() {
    super.init();
    this.isRecoveryEmail = this.route.snapshot.data.recoveryEmail === true;
    this.emailfailed = false;
    this.emailsent = this.appStateService.getAppState().hasNotification(AppConstants.SN_LOGIN_EMAIL_VALIDATION_SENT);
    this.validationFailed = false;
    this.genericError = false;
    this.validationCodeExpired = false;
    this.subscribeParamsState();
    this.sendEmailTimeout = ValidateEmailComponent.INITIAL_SEND_TIMEOUT;
    this.sendEmailTimeoutCounter = timer(1000, 1000).subscribe(() => {
      this.sendEmailTimeout--;
      if (this.sendEmailTimeout <= 0) {
        this.sendEmailTimeout = undefined;
        this.sendEmailTimeoutCounter.unsubscribe();
        this.sendEmailTimeoutCounter = undefined;
      }
    });
  }
  ngOnDestroy() {
    if (this.sendEmailTimeoutCounter) {
      this.sendEmailTimeout = undefined;
      this.sendEmailTimeoutCounter.unsubscribe();
      this.sendEmailTimeoutCounter = undefined;
    }
  }
  recoveryEmailNotFound(): boolean {
    return !!this.isRecoveryEmail && !!this.recoveryEmailId && !this.recoveryEmail;
  }
  private subscribeParamsState() {
    const keyPromise = this.paramsService.getParam(AppConstants.QP_EMAIL_VALIDATION_KEY);
    const idPromise = this.paramsService.getParam(AppConstants.QP_EMAIL_VALIDATION_ID);
    const errorPromise = this.paramsService.getParam(AppConstants.QP_ERROR);
    this.recoveryEmail = undefined;

    Promise.all([keyPromise, idPromise, errorPromise]).then((values) => {


      if (!this.isRecoveryEmail) {
        this.alreadyValidated = this.appStateService.getAppState().getAuthenticationState().getExistingAuthentications()
          .findIndex((v) => v.authenticationMethod === 'emailVerification') >= 0;
      } else {
        let id;
        if (Array.isArray(values[1])) {
          id = values[1][0];
        } else {
          id = values[1];
        }
        if (id) {
          this.recoveryEmailId = id;
          this.recoveryEmailApi.getRecoveryEmail(id).subscribe((response) => {
            this.alreadyValidated = !!response.validatedAt;
            this.recoveryEmail = response;
          });
        }
      }

      const keyUrlParams = values[0];
      let key;
      if (keyUrlParams) {
        if (Array.isArray(keyUrlParams)) {
          key = keyUrlParams[0];
        } else {
          key = keyUrlParams;
        }
      }
      this.key = key;
      if (this.key) {
        this.validationForm.get('key').setValue(this.key);
      }
      if (this.key && this.appStateService.getAppState().getAuthenticationState().hasSession() &&
        (!this.isRecoveryEmail || !!this.recoveryEmailId)) {
        this.logger.debug('auto submitting valid form');
        this.submit();
      }
      if (!!values[2]) {
        this.setShowServerError(values[2]);
      } else if (this.showServerError && this.showServerError !== 'error') {
        // only clear previous parameter based errors
        this.setShowServerError(undefined);

      }
    });
  }

  private continueToNext() {
    const t: ParamsForRouteNav | string = this.appStateService.getAppState().resolveNextRoute([
      AppConstants.QP_EMAIL_VALIDATION_KEY,
      AppConstants.QP_EMAIL,
      AppConstants.QP_LOGIN_HINT,
      AppConstants.QP_ERROR,
      AppConstants.UI_SESSION_ID,
    ]);
    this.logger.debug('Validating email completed: %o', t);
    if (typeof t === 'string') {
      this.processingService.show();
      window.location.href = t as string;
    } else if (t) {
      if (this.isRecoveryEmail && t.path === AppConstants.PATH_PROFILE) {
        this.appStateService.getAppState().addNotification('recovery-email-validated');
        this.router.navigate([AppConstants.PATH_MANAGE_EMAILS], {
          queryParams: {
            [AppConstants.QP_EMAIL_VALIDATION_ID]: this.recoveryEmailId,
          },
        });
      } else if (t.path === AppConstants.PATH_PROFILE) {
        this.appStateService.getAppState().addNotification('login-email-validated');
        this.router.navigate([AppConstants.PATH_PROFILE], {
          queryParams: {},
        });
      } else {
        this.router.navigate([t.path], {
          queryParams: t.queryParams,
          fragment: t.fragment,
        });
      }
    }
  }

  submit() {
    const k = this.validationForm.get('key').value;
    this.validationCodeExpired = false;
    this.validationFailed = false;
    this.genericError = false;
    this.logger.debug('Submitted: %s', k);
    const verification: VerifyLoginEmailCredential = {
      authenticationMethod: this.isRecoveryEmail ? AppConstants.AC_AM_VERIFY_RECOVERY_EMAIL : AppConstants.AC_AM_VERIFY_EMAIL,
      verificationCode: k
    };
    const handler = (response) => {
      this.tooManyAttemptsError = undefined;
      this.tooManyAttemptsTimeout = undefined;
      if (this.tooManyAttemptsTimeoutCounter) {
        this.tooManyAttemptsTimeoutCounter.unsubscribe();
      }
      this.tooManyAttemptsTimeoutCounter = undefined;

      this.continueToNext();
    };
    const errorHandler = (response) => {
      this.logger.debug('Validating email failed, response %O', response);
      this.tooManyAttemptsError = 'authentication_attempts_restricted' === response.error.error;
      if (!this.tooManyAttemptsError) {
        this.validationFailed = true;
      }
      if (response.error.waitSeconds > 2) {
        // better to not react if less than 2 seconds, as there is not enough time for the user to read the info
        this.tooManyAttemptsTimeout = response.error.waitSeconds;
        this.tooManyAttemptsTimeoutCounter = timer(1000, 1000).subscribe(() => {
          this.tooManyAttemptsTimeout--;
          if (this.tooManyAttemptsTimeout <= 0) {
            this.tooManyAttemptsTimeout = undefined;
            this.tooManyAttemptsTimeoutCounter.unsubscribe();
            this.tooManyAttemptsTimeoutCounter = undefined;
          }
        });
      }
      if (response && response.error === 'credentials_expired') {
        this.validationCodeExpired = true;
      }
      this.genericError = true;
    };
    // Apparently assignin the function call directly to the action variable for easy usage breaks the function.
    if (this.isRecoveryEmail) {
      this.appStateService.addRecoveryEmailAuthentication([verification], false)
        .subscribe(handler, errorHandler)
      ;
    } else {
      this.appStateService.addAuthentications([verification], false)
        .subscribe(handler, errorHandler)
      ;
    }
  }

  stripErrorParamFromCurrentUrl(): string {
    const currentUrl = window.location.href.split('?');
    let strippedUrl = currentUrl[0];
    if (currentUrl.length > 1) {
      const params = currentUrl[1].split('&').filter((f) => !f.startsWith(AppConstants.QP_ERROR + '='));
      if (params.length > 0) {
        strippedUrl += '?' + params.join('&');
      }
    }
    return strippedUrl;
  }
  public setShowServerError(error: string|undefined) {
    if (error === undefined) {
      const t = this.stripErrorParamFromCurrentUrl();
      if (t !== window.location.href) {
        window.history.replaceState({}, document.title, t);
      }
    }
    this.showServerError = error;
  }
  sendEmail() {
    this.emailfailed = false;
    this.emailsent = false;
    const verificationUri = [];
    verificationUri.push(LocationUtil.getOwnDomainURL());
    verificationUri.push(getAppBaseHref());
    if (this.isRecoveryEmail) {
      verificationUri.push(AppConstants.ROUTE_VALIDATE_RECOVERY_EMAIL);
    } else {
      verificationUri.push(AppConstants.ROUTE_VALIDATE_EMAIL);
    }
    if (verificationUri[0].indexOf('?') < 0) {
      verificationUri.push('?');
    } else {
      verificationUri.push('&');
    }
    verificationUri.push(AppConstants.QP_EMAIL_VALIDATION_KEY);
    verificationUri.push('={0}');

    verificationUri.push('&');
    verificationUri.push(AppConstants.UI_SESSION_ID);
    verificationUri.push('=');
    verificationUri.push(this.appStateService.getAppState().uiSessionId);

    verificationUri.push(
      LocationUtil.validParamsToQueryString(
        '&',
        [AppConstants.QP_EMAIL_VALIDATION_KEY, AppConstants.QP_ERROR, AppConstants.UI_SESSION_ID],
          this.configuration.getProperties().allowedOriginsForNextParam)
    );
    if (!this.isRecoveryEmail) {
      this.emailVerificationApi.sendEmailVerification({verificationUri: verificationUri.join('')}, 'response')
        .subscribe(response => {
          this.logger.debug('Sending email completed, response %o', response);
          this.emailsent = true;
          this.sendEmailTimeout = ValidateEmailComponent.RESEND_TIMEOUT;
          this.sendEmailTimeoutCounter = timer(1000, 1000).subscribe(() => {
            this.sendEmailTimeout--;
            if (this.sendEmailTimeout <= 0) {
              this.sendEmailTimeout = undefined;
              this.sendEmailTimeoutCounter.unsubscribe();
              this.sendEmailTimeoutCounter = undefined;
            }
          });
        }, response => {
          this.logger.debug('Sending email  failed, response %o', response);
          this.emailfailed = true;
        })
      ;
    } else if (this.recoveryEmail) {
      this.recoveryEmailApi.sendRecoveryEmailVerification({ verificationUri: verificationUri.join('') },
        this.recoveryEmail.id as string).subscribe((response) => {
          this.logger.debug('Sending email completed, response %o', response);
          this.emailsent = true;
          this.sendEmailTimeout = ValidateEmailComponent.RESEND_TIMEOUT;
          this.sendEmailTimeoutCounter = timer(1000, 1000).subscribe(() => {
            this.sendEmailTimeout--;
            if (this.sendEmailTimeout <= 0) {
              this.sendEmailTimeout = undefined;
              this.sendEmailTimeoutCounter.unsubscribe();
              this.sendEmailTimeoutCounter = undefined;
            }
          });
        }, response => {
        this.logger.debug('Sending email  failed, response %o', response);
        this.emailfailed = true;
      })
      ;
    }
  }

  hideNotification(n: string) {
    if (n && n === AppConstants.SN_LOGIN_EMAIL_VALIDATION_SENT) {
      this.appStateService.getAppState().removeNotification(AppConstants.SN_LOGIN_EMAIL_VALIDATION_SENT);
      this.emailsent = false;
    }
  }

  checkAndContinue() {
    this.validationCheckFailed = false;
    this.appStateService.addAuthentications([{ authenticationMethod: 'emailVerification' } as VerifyLoginEmailCredential], false)
      .subscribe((response) => {
        const isErrorResponse = response instanceof HttpErrorResponse;
        if (isErrorResponse) {
          this.validationCheckFailed = true;
        } else {
          const chall = ResponseUtil.readChallenges(response);
          if (!chall.required.find((val) => val.authenticationMethod === AppConstants.AC_AM_VERIFY_EMAIL)) {
            this.continueToNext();
          } else {
            this.validationCheckFailed = true;
          }
        }
      }, (error) => {
        this.validationCheckFailed = true;
      })
    ;
  }

  cancel() {
    if (this.appStateService.getAppState().getAuthenticationState().hasSession() &&
      this.appStateService.getAppState().getAuthenticationState().isFullyAuthenticated()) {
      if (this.isRecoveryEmail) {
        this.router.navigate([AppConstants.PATH_MANAGE_EMAILS], {
          relativeTo: this.route,
        });
      } else {
        this.router.navigate([AppConstants.PATH_PROFILE], {
          relativeTo: this.route,
        });
      }
    } else if (this.appStateService.getAppState().getAuthenticationState().hasSession()) {
      this.router.navigate([AppConstants.PATH_LOGOUT], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate([AppConstants.PATH_HOME], {
        relativeTo: this.route,
      });
    }
    return false;
  }
  resolveEmail(): string {
    let retVal;
    if (!this.isRecoveryEmail) {
      retVal = this.appStateService.getAppState().getAuthenticationState().getProfile().email;
    } else if (this.recoveryEmail) {
      retVal = this.recoveryEmail.value;
    }
    if (!retVal) {
      retVal = this.translate.get('validate-email.email-value-fallback');
    }
    return retVal;
  }
  hasError(field?: AbstractControl, code?: string): boolean {
    if (field && field === this.validationForm.get('key') && this.validationFailed && this.validationCodeExpired) {
      return true;
    } else {
      return this.genericError;
    }
  }

  public hasTooManyAttemptsTimeout(): boolean {
    return this.tooManyAttemptsTimeout > 0;
  }
  isFieldInvalid(field: AbstractControl): boolean {
    return this.hasError(field) || this.isInputInvalid(field);
  }

  // noinspection JSMethodCanBeStatic
  isInputInvalid(field: AbstractControl): boolean {
    return field.invalid && field.touched;
  }

  isFieldValid(field: AbstractControl): boolean {
    return !this.hasError(field) && this.isInputValid(field);
  }

  // noinspection JSMethodCanBeStatic
  isInputValid(field: AbstractControl): boolean {
    return field.valid && field.touched;
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('validate-email.window-title');
  }

}
